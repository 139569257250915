<template>
  <KTPortlet :title="form.name" headSize="md">
    <template v-slot:body>
      <b-form @submit.prevent="onSubmit">
        <b-form-group id="input-group-1" label="Tên" label-for="input-1">
          <b-form-input
            id="input-1"
            type="text"
            required
            v-model="form.name"
          ></b-form-input>
        </b-form-group>
        <b-form-group
          id="input-group-2"
          label="Kiểu khuyến mại"
          label-for="input-6"
        >
          <b-form-select
            id="input-6"
            v-model="form.typePromo"
            :options="listTypePromo"
            value-field="value"
            text-field="text"
            required
          >
          </b-form-select>
        </b-form-group>
        <b-form-group id="input-group-3" label="Giá trị" label-for="input-3">
          <b-form-input
            id="input-3"
            type="number"
            v-model="form.value"
            min="0"
            required
          ></b-form-input>
        </b-form-group>
        <b-form-group id="input-group-3" label="Trạng thái" label-for="input-4">
          <b-form-select
            id="input-4"
            v-model="form.status"
            :options="listStatus"
            value-field="value"
            text-field="text"
            required
          ></b-form-select>
        </b-form-group>

        <b-form-group label="Tiệm">
          <v-select
            :options="allOutlets"
            label="name"
            v-model="outLetsSelected"
            multiple
          >
            <template #search="{attributes, events}">
              <input
                class="vs__search"
                :required="!outLetsSelected"
                v-bind="attributes"
                v-on="events"
              />
            </template>
          </v-select>
        </b-form-group>

        <b-form-group id="input-group-3" label="Mô tả" label-for="input-5">
          <b-form-textarea
            id="textarea"
            v-model="form.description"
            required
            placeholder="Enter something..."
            rows="3"
            max-rows="6"
          ></b-form-textarea>
        </b-form-group>
        <b-button type="submit" variant="primary">Hoàn thành</b-button>
      </b-form>
    </template>
  </KTPortlet>
</template>

<style lang="scss">
@import "./Promo.scss";
</style>

<script>
import KTPortlet from "@/components/Portlet.vue";
import { UserRole } from "./../../constans";
import { mapGetters } from "vuex";
import vSelect from "vue-select";
import Loader from "@/common/loader";
import Swal from "sweetalert2";
import { updateSuccess } from "@/helper/index";

export default {
  name: "Create",
  components: {
    KTPortlet,
    vSelect
  },
  data() {
    return {
      form: {
        name: "",
        typePromo: "",
        description: "",
        value: "",
        status: "",
        outletId: ""
      },
      outLetsSelected: [],
      listTypePromo: [
        {
          value: 0,
          text: "Phần trăm"
        },
        {
          value: 1,
          text: "Tiền"
        }
      ],
      listStatus: [
        {
          value: 1,
          text: "Kích hoạt"
        },
        {
          value: 2,
          text: "Chưa kích hoạt"
        }
      ],
      allOutlets: []
    };
  },

  created() {
    this.$store.dispatch("resetListOutlets");
    this.getListOutlet();
    this.getListUser();
  },

  methods: {
    onSubmit() {
      const listOutletId = this.outLetsSelected.map(item => {
        return item._id;
      });
      const queryParams = {
        id: this.form._id,
        params: {
          description: this.form.description,
          name: this.form.name,
          outletId: listOutletId,
          status: this.form.status,
          typePromo: this.form.typePromo,
          value: this.form.value
        }
      };
      this.$store.dispatch("updatePromo", queryParams).then(() => {
        updateSuccess("promo");
      });
    },

    getListUser() {
      this.$store
        .dispatch("getUsers", { role: UserRole.OutletBoss })
        .then(() => {
          this.listManager = this.$store.state.auth.listUser;
        });
    },

    getListOutlet() {
      Loader.fire();
      this.$store
        .dispatch("setOutlets", { status: 1 })
        .then(() => {
          this.allOutlets = this.$store.state.outlet.outlets;
          const item = this.$router.history.current.query?.item;
          if (item) {
            this.form = { ...this.form, ...item };
            item.outletId.forEach(id => {
              const temmp = this.allOutlets.find(
                itemOutlet => itemOutlet._id === id
              );
              this.outLetsSelected.push(temmp);
            });
            this.$router.replace({ params: { item: item._id } });
          }
        })
        .finally(Loader.close);
    }
  }
};
</script>
